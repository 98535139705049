import React from "react";
import Layout from "../layout";
import Container from "../components/Container";

const ErrorPage: React.FC = () => {
  return (
    <Layout>
      <Container>
        <h1>404 - Not Found :(</h1>
      </Container>
    </Layout>
  );
};

export default ErrorPage;
